<template lang="pug">
  .own-shop-prices
    .shop {{ shopName }}
    .price-wrapper(v-for="date in dateRange")
      .manual-mode(v-if="isManualMode")
        .price
          .yellow-dot(v-if="changedPrice(date)")
          span {{ $n(inputPriceValue(date)) || "—" }}
      .auto-price-ai-mode(v-else)
        .current-price
          span {{ $n(currentPrice(date)) || "—" }}
        .recommended-price(:id="`recommended-price-${date}`")
          RecommendedPrice(
            :current-price="currentPrice(date)"
            :recommended-price="recommendedPrice(date)"
          )
        BTooltip(
          v-if="isAutoPriceMode"
          :target="`recommended-price-${date}`"
          triggers="hover"
        )
          .tooltip-row
            .title {{ $t("price_calendar.calendar.price_modal.tooltip.lead_time") }}
            .value {{ leadTimeToDisplay(date) || "—" }}
          .tooltip-row
            .title {{ $t("price_calendar.calendar.price_modal.tooltip.availability") }}
            .value {{ inventoryByDay(date).available_inventory || "—" }}
          .tooltip-row
            .title {{ $t("price_calendar.calendar.price_modal.tooltip.rank") }}
            .rank.value {{ rankName(date) || "—" }}
          .tooltip-row
            .title {{ $t("price_calendar.calendar.price_modal.tooltip.formula") }}
            .value {{ pricesRankTranslationByDate(date) || "—" }}
          .tooltip-row
            .title {{ $t("price_calendar.calendar.price_modal.tooltip.competitor_price") }}
            .value {{ relativelyPriceValue(date) || "—" }}
          .tooltip-row
            .title {{ $t("price_calendar.calendar.price_modal.tooltip.price_range") }}
            .value {{ priceRange || "—" }}
        .applying-price
          AppNumberInput(
            :class="priceClassName(date)"
            :disabled="isCommonPrice"
            :value="inputPriceValue(date)"
            :max="Number.POSITIVE_INFINITY"
            :invalid="invalidPrices[dateStringsByDates[date]]"
            @input="handleInputPrice(date, $event)"
          )
</template>

<script>
  // components
  import { BTooltip } from "bootstrap-vue"

  // misc
  import { isManualMode, isAutoPriceMode } from "@/helpers/price-calendar"
  import { mapValues, isUndefined, get, isNumber } from "lodash-es"
  import { pricesRankTranslation } from "@/pages/PriceManagement/GeneralSettings/RuleSettings/helpers"

  export default {
    components: {
      BTooltip,
      AppNumberInput: () => import("@/components/elements/AppNumberInput"),
      RecommendedPrice: () => import("@/pages/PriceManagement/PriceCalendar/Calendar/CarClassData/RecommendedPrice")
    },

    props: {
      shopName: String,
      dateRange: Array,
      isCommonPrice: Boolean,
      currentPlan: Object,
      rentalPeriod: String,
      currentPrices: Object,
      changedPrices: {
        type: Object,
        default: () => new Object()
      },
      recommendedPrices: Object,
      groupedFormattedDates: Object
    },

    computed: {
      isManualMode,
      isAutoPriceMode,

      dateStringsByDates({ groupedFormattedDates }) {
        return mapValues(groupedFormattedDates, ({ fullDate }) => fullDate)
      },

      invalidPrices({ changedPrices }) {
        return mapValues(changedPrices, value => value <= 0)
      },

      ranks() {
        return get(this.currentPlan.recommended_prices, "ranks") || {}
      },

      priceRange() {
        const { price_min, price_max } = get(this.currentPlan.recommended_prices, "price_range")
        return isNumber(price_min) && isNumber(price_max) ? `${price_min} ~ ${price_max}` : undefined
      }
    },

    methods: {
      priceClassName(date) {
        return {
          invalid: this.invalidPrices[this.dateStringsByDates[date]],
          "changed-manually": this.isPriceChangedManually(this.dateStringsByDates[date]),
          "changed-auto": this.isPriceChangedAuto(this.dateStringsByDates[date])
        }
      },

      isPriceChangedManually(date) {
        return !isUndefined(this.changedPrices[date]) && this.changedPrices[date] !== this.currentPrices[date]
      },

      isPriceChangedAuto(date) {
        return this.changedPrices[date] === this.recommendedPrices[date] && this.isPriceChangedManually(date)
      },

      currentPrice(date) {
        return this.currentPrices[this.dateStringsByDates[date]]?.value
      },

      recommendedPrice(date) {
        return this.recommendedPrices[this.dateStringsByDates[date]]
      },

      relativelyPriceValue(date) {
        return get(this.rankByDate(date), `relatively_prices_by_rental_period.${this.rentalPeriod}`)
      },

      changedPrice(date) {
        return this.changedPrices[this.dateStringsByDates[date]]
      },

      inputPriceValue(date) {
        const changed = this.changedPrices[this.dateStringsByDates[date]]
        return changed === 0 ? changed : changed || this.currentPrice(date)
      },

      handleInputPrice(date, value) {
        this.$emit("change", { value, date: this.dateStringsByDates[date] })
      },

      inventoryByDay(date) {
        return get(this.currentPlan, `inventories.${this.dateStringsByDates[date]}`) || {}
      },

      rankByDate(date) {
        return this.ranks[this.dateStringsByDates[date]]
      },

      rankName(date) {
        return this.rankByDate(date)?.name
      },

      pricesRankTranslationByDate(date) {
        const rank = this.rankByDate(date)
        return rank ? pricesRankTranslation(rank) : undefined
      },

      leadTimeToDisplay(date) {
        const leadTime = this.rankByDate(date)?.lead_time
        return leadTime ? this.leadTimeTranslation(leadTime) : undefined
      },

      leadTimeTranslation(num) {
        return this.$t("price_calendar.calendar.price_modal.tooltip.days", { num: num })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/price-calendar.sass"

  .own-shop-prices
    +table-row
    background-color: $default-gray-light
    border-radius: 10px

    .price-wrapper
      width: 80%

      .manual-mode
        .price
          flex-direction: row

          .yellow-dot
            +yellow-dot

      .auto-price-ai-mode
        .current-price
          cursor: default
          user-select: none

        .current-price,
        .applying-price
          margin-bottom: 5px
          text-align: center

          ::v-deep
            .app-number-input
              input
                height: 20px
                width: 100%

            &.changed-manually
              input
                +changed-manually

            &.changed-auto
              input
                +changed-auto

        .recommended-price
          margin-bottom: 5px

  +tooltip($default-purple)

  ::v-deep
    .tooltip-inner
      max-width: 250px

      .tooltip-row
        align-items: center
        display: flex
        justify-content: space-between
        font-size: 0.75em
        width: 250px

        .rank
          text-transform: uppercase

        .title,
        .value
          text-align: left

        .title
          width: 40%

        .value
          font-weight: 600
          width: 60%
</style>
