import { i18n } from "@/config"

export const pricesRankTranslation = rank => {
  return rank.math_symbol === "=" ? samePriceTranslation(rank) : notSamePriceTranslation(rank)
}

const samePriceTranslation = ({ relatively_price }) => {
  return i18n.t("general_settings.rule_settings.ranks.same_as", {
    relatively_price: relativelyPriceTranslation(relatively_price)
  })
}

const notSamePriceTranslation = ({ relatively_price, math_symbol, value }) => {
  return i18n.t("general_settings.rule_settings.ranks.relatively_price", {
    relatively_price: relativelyPriceTranslation(relatively_price),
    math_symbol,
    value
  })
}

const relativelyPriceTranslation = relativePrice => {
  return i18n.t(`general_settings.rule_settings.ranks.${relativePrice}`)
}
